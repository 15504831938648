import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import './SideDrawer.css';

const sideDrawer = props => {
  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses = 'side-drawer open';
  }
  return (
    <nav className={drawerClasses}>
      <div className="close-icon">
        <FontAwesomeIcon icon={faWindowClose} size="2x" onClick={props.click}/>
      </div>
      <ul>
        <li><a href="#bio">About</a></li>
        <li><a href="#portfolio">Projects</a></li>
        <li><a href="#footer">Contact</a></li>
      </ul>
    </nav>
  )

}

export default sideDrawer;